import React from "react";

const Rewards = (props) => {
    return (
        <React.Fragment>
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 26.993 26.993">
                <g id="Group_923" data-name="Group 923" transform="translate(-213.423 -393.858)">
                    <path id="Path_917" data-name="Path 917" d="M239.825,405.455a4.561,4.561,0,0,1-.779-2.907,3.346,3.346,0,0,0-1.9-3.291,4.553,4.553,0,0,1-2.128-2.128,3.345,3.345,0,0,0-3.291-1.9,4.562,4.562,0,0,1-2.907-.779,3.346,3.346,0,0,0-3.8,0,4.562,4.562,0,0,1-2.907.779,3.346,3.346,0,0,0-3.291,1.9,4.553,4.553,0,0,1-2.128,2.128,3.346,3.346,0,0,0-1.9,3.291,4.559,4.559,0,0,1-.779,2.907,3.346,3.346,0,0,0,0,3.8,4.558,4.558,0,0,1,.779,2.907,3.346,3.346,0,0,0,1.9,3.291,4.554,4.554,0,0,1,2.128,2.128,3.346,3.346,0,0,0,3.291,1.9q.171-.013.342-.013a4.561,4.561,0,0,1,2.565.792,3.346,3.346,0,0,0,3.8,0,4.558,4.558,0,0,1,2.907-.779,3.345,3.345,0,0,0,3.291-1.9,4.554,4.554,0,0,1,2.128-2.128,3.346,3.346,0,0,0,1.9-3.291,4.56,4.56,0,0,1,.779-2.907A3.346,3.346,0,0,0,239.825,405.455ZM226.92,418.5a11.146,11.146,0,1,1,11.146-11.146A11.158,11.158,0,0,1,226.92,418.5Z" fill={props.color} />
                    <path id="Path_918" data-name="Path 918" d="M235.871,406.332a9.973,9.973,0,1,0,9.972,9.972A9.984,9.984,0,0,0,235.871,406.332Zm5.415,9.463L239.3,417.73a.358.358,0,0,0-.1.318l.468,2.73a.8.8,0,0,1-.177.661.814.814,0,0,1-.623.292.8.8,0,0,1-.377-.094l-2.453-1.289a.366.366,0,0,0-.334,0l-2.453,1.289a.82.82,0,0,1-1-.2.8.8,0,0,1-.177-.661l.468-2.73a.359.359,0,0,0-.1-.318l-1.982-1.934a.812.812,0,0,1,.449-1.385l2.741-.4a.358.358,0,0,0,.27-.2l1.227-2.484a.812.812,0,0,1,1.456,0l1.227,2.484a.358.358,0,0,0,.27.2l2.741.4a.812.812,0,0,1,.45,1.385Z" transform="translate(-8.951 -8.95)" fill={props.color} />
                </g>
            </svg>
        </React.Fragment>
    );
};
export default Rewards;
