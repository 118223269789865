import React from "react";

const Library = (props) => {
    return (
        <React.Fragment>
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="30px" height="30px">
                <path fill={props.color} d="M 12 2.9824219 L 3 6.3027344 L 3 9 L 5 9 L 5 17 L 7 17 L 7 9 L 11 9 L 11 17 L 13 17 L 13 9 L 17 9 L 17 17 L 19 17 L 19 9 L 21 9 L 21 8 L 21 6.3027344 L 12 2.9824219 z M 12 5.1132812 L 17.111328 7 L 6.8886719 7 L 12 5.1132812 z M 3 19 L 3 21 L 21 21 L 21 19 L 3 19 z" /></svg> */}

            <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 14.667 16">
                <path id="icons8-layers" d="M11.333,3a1.322,1.322,0,0,0-.6.148h-.012L4.417,6.384a.665.665,0,0,0-.005,1.232v.013l6.316,3.225.005,0a1.288,1.288,0,0,0,1.2,0l.005,0,6.316-3.225V7.616a.665.665,0,0,0-.005-1.232L11.944,3.148h-.012A1.322,1.322,0,0,0,11.333,3ZM5.651,9.751l-1.234.633a.665.665,0,0,0-.005,1.232v.013l6.316,3.225.005,0a1.288,1.288,0,0,0,1.2,0l.005,0,6.316-3.225v-.013a.665.665,0,0,0-.005-1.232l-1.234-.633c-1.937.991-4.58,2.34-4.611,2.352a2.609,2.609,0,0,1-2.147,0C10.228,12.091,7.588,10.743,5.651,9.751Zm0,4-1.234.633a.665.665,0,0,0-.005,1.232v.013l6.316,3.225.005,0a1.288,1.288,0,0,0,1.2,0l.005,0,6.316-3.225v-.013a.665.665,0,0,0-.005-1.232l-1.234-.633c-1.937.991-4.58,2.34-4.611,2.352a2.609,2.609,0,0,1-2.147,0C10.228,16.091,7.588,14.743,5.651,13.751Z" transform="translate(-4 -3)" fill={props.color} />
            </svg>
        </React.Fragment>
    );
};
export default Library;
