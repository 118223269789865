import React from "react";

const Org = (props) => {
    return (
        <React.Fragment>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                    fill={props.color} stroke="none">
                    <path d="M2062 4469 c-46 -14 -118 -88 -131 -135 -7 -25 -11 -202 -11 -494 0
-292 4 -469 11 -494 14 -50 82 -118 135 -134 23 -7 96 -12 163 -12 l121 0 -2
-212 -3 -213 -572 -5 -571 -5 -43 -30 c-23 -16 -53 -50 -66 -74 -23 -44 -23
-47 -23 -392 l0 -347 -143 -4 c-137 -3 -145 -4 -185 -31 -23 -15 -54 -46 -69
-69 l-28 -42 -3 -473 c-2 -321 1 -486 8 -514 14 -53 82 -120 136 -137 59 -17
929 -17 988 0 53 16 118 81 134 134 17 59 17 929 0 988 -16 53 -81 118 -134
134 -23 7 -96 12 -163 12 l-121 0 2 213 3 212 1065 0 1065 0 3 -212 2 -211
-143 -4 c-137 -3 -145 -4 -185 -31 -23 -15 -54 -46 -69 -69 l-28 -42 -3 -473
c-2 -321 1 -486 8 -514 14 -53 82 -120 136 -137 59 -17 929 -17 988 0 53 16
118 81 134 134 17 59 17 929 0 988 -16 53 -81 118 -134 134 -23 6 -96 12 -162
12 l-121 0 -3 358 -3 359 -27 39 c-15 21 -44 50 -65 64 l-37 25 -570 5 -571 5
-3 213 -2 212 121 0 c67 0 140 5 163 12 53 16 118 81 134 134 17 59 17 929 0
988 -16 53 -81 118 -134 134 -51 15 -940 16 -992 1z m708 -629 l0 -210 -210 0
-210 0 0 210 0 210 210 0 210 0 0 -210z m-1280 -2560 l0 -210 -210 0 -210 0 0
210 0 210 210 0 210 0 0 -210z m2560 0 l0 -210 -210 0 -210 0 0 210 0 210 210
0 210 0 0 -210z"/>
                </g>
            </svg>

        </React.Fragment>
    );
};
export default Org;
