import React from "react";

const Heart = () => {

	return (
		<React.Fragment>
			<div className="animate_heart heart_one">
				<svg xmlns="http://www.w3.org/2000/svg" width="12.984" height="11.539" viewBox="0 0 12.984 11.539">
					<path id="Path_34" data-name="Path 34" d="M9.559,28.507H9.537a3.477,3.477,0,0,0-3.044,2.055,3.487,3.487,0,0,0-3.044-2.055h-.03A3.578,3.578,0,0,0,0,32.023c-.114,2.4,1.8,4.011,4.661,6.447.495.426,1.02.875,1.576,1.355l.251.221.251-.221c.548-.48,1.081-.929,1.576-1.355,2.862-2.436,4.775-4.049,4.661-6.447A3.584,3.584,0,0,0,9.559,28.507Z" transform="translate(0 -28.507)" fill="#ff3636"></path>
				</svg>
			</div>
			<div className="animate_heart heart_two">
				<svg xmlns="http://www.w3.org/2000/svg" width="12.984" height="11.539" viewBox="0 0 12.984 11.539">
					<path id="Path_34" data-name="Path 34" d="M9.559,28.507H9.537a3.477,3.477,0,0,0-3.044,2.055,3.487,3.487,0,0,0-3.044-2.055h-.03A3.578,3.578,0,0,0,0,32.023c-.114,2.4,1.8,4.011,4.661,6.447.495.426,1.02.875,1.576,1.355l.251.221.251-.221c.548-.48,1.081-.929,1.576-1.355,2.862-2.436,4.775-4.049,4.661-6.447A3.584,3.584,0,0,0,9.559,28.507Z" transform="translate(0 -28.507)" fill="#ff3636"></path>
				</svg>
			</div>
			<div className="animate_heart heart_three">
				<svg xmlns="http://www.w3.org/2000/svg" width="12.984" height="11.539" viewBox="0 0 12.984 11.539">
					<path id="Path_34" data-name="Path 34" d="M9.559,28.507H9.537a3.477,3.477,0,0,0-3.044,2.055,3.487,3.487,0,0,0-3.044-2.055h-.03A3.578,3.578,0,0,0,0,32.023c-.114,2.4,1.8,4.011,4.661,6.447.495.426,1.02.875,1.576,1.355l.251.221.251-.221c.548-.48,1.081-.929,1.576-1.355,2.862-2.436,4.775-4.049,4.661-6.447A3.584,3.584,0,0,0,9.559,28.507Z" transform="translate(0 -28.507)" fill="#ff3636"></path>
				</svg>
			</div>
			<div className="animate_heart heart_three">
				<svg xmlns="http://www.w3.org/2000/svg" width="12.984" height="11.539" viewBox="0 0 12.984 11.539">
					<path id="Path_34" data-name="Path 34" d="M9.559,28.507H9.537a3.477,3.477,0,0,0-3.044,2.055,3.487,3.487,0,0,0-3.044-2.055h-.03A3.578,3.578,0,0,0,0,32.023c-.114,2.4,1.8,4.011,4.661,6.447.495.426,1.02.875,1.576,1.355l.251.221.251-.221c.548-.48,1.081-.929,1.576-1.355,2.862-2.436,4.775-4.049,4.661-6.447A3.584,3.584,0,0,0,9.559,28.507Z" transform="translate(0 -28.507)" fill="#ff3636"></path>
				</svg>
			</div>
			<div className="animate_heart heart_four">
				<svg xmlns="http://www.w3.org/2000/svg" width="12.984" height="11.539" viewBox="0 0 12.984 11.539">
					<path id="Path_34" data-name="Path 34" d="M9.559,28.507H9.537a3.477,3.477,0,0,0-3.044,2.055,3.487,3.487,0,0,0-3.044-2.055h-.03A3.578,3.578,0,0,0,0,32.023c-.114,2.4,1.8,4.011,4.661,6.447.495.426,1.02.875,1.576,1.355l.251.221.251-.221c.548-.48,1.081-.929,1.576-1.355,2.862-2.436,4.775-4.049,4.661-6.447A3.584,3.584,0,0,0,9.559,28.507Z" transform="translate(0 -28.507)" fill="#ff3636"></path>
				</svg>
			</div>
			<div className="animate_heart heart_five">
				<svg xmlns="http://www.w3.org/2000/svg" width="12.984" height="11.539" viewBox="0 0 12.984 11.539">
					<path id="Path_34" data-name="Path 34" d="M9.559,28.507H9.537a3.477,3.477,0,0,0-3.044,2.055,3.487,3.487,0,0,0-3.044-2.055h-.03A3.578,3.578,0,0,0,0,32.023c-.114,2.4,1.8,4.011,4.661,6.447.495.426,1.02.875,1.576,1.355l.251.221.251-.221c.548-.48,1.081-.929,1.576-1.355,2.862-2.436,4.775-4.049,4.661-6.447A3.584,3.584,0,0,0,9.559,28.507Z" transform="translate(0 -28.507)" fill="#ff3636"></path>
				</svg>
			</div>
			<div className="animate_heart heart_six">
				<svg xmlns="http://www.w3.org/2000/svg" width="12.984" height="11.539" viewBox="0 0 12.984 11.539">
					<path id="Path_34" data-name="Path 34" d="M9.559,28.507H9.537a3.477,3.477,0,0,0-3.044,2.055,3.487,3.487,0,0,0-3.044-2.055h-.03A3.578,3.578,0,0,0,0,32.023c-.114,2.4,1.8,4.011,4.661,6.447.495.426,1.02.875,1.576,1.355l.251.221.251-.221c.548-.48,1.081-.929,1.576-1.355,2.862-2.436,4.775-4.049,4.661-6.447A3.584,3.584,0,0,0,9.559,28.507Z" transform="translate(0 -28.507)" fill="#ff3636"></path>
				</svg>
			</div>
		</React.Fragment>
	)
}
export default Heart;